<template>
  <upload-default label="Faça upload do comprovante aqui" class="upload-comprovante">
  </upload-default>
</template>

<script>
import UploadDefault from '../../../../../reuse/upload/UploadDefault'
export default {
  name: 'UploadComprovante',
  components: {UploadDefault}
}
</script>

<style lang="stylus">
</style>
