<template>
  <div class="upload-default">
    <div class="label-upload">{{label}}</div>
  </div>
</template>

<script>
export default {
  name: 'UploadDefault',
  props: {
    label: {
      required: true,
      default: 'Arraste o(s) arquivo(s) aqui'
    }
  }
}
</script>

<style lang="stylus">
  .upload-default {
    background-color transparent
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16)
    position relative
    border-radius 20px
    display block
    width 100%
    min-height 72px
    cursor pointer

    &:hover{
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.26)
      border #000 1px dotted
    }

    .label-upload {
      position absolute
      display flex
      left 0
      top 0
      bottom 0
      width 100%
      font-size 13px
      color #ABABAB
      font-weight bold
      align-items center
      justify-content center
    }
  }
</style>
