<template>
  <window-content v-if="!loading && !errors && !success">
    <e-row class="flex justify-center m-t-lg m-b-lg">
      <e-col style="max-width: 520px" class="lote-registro-pagamento">
        <e-row>
          <e-col class="lrp-col">
            <div class="text-center font-bold m-b-xs text-grey-7">Quem está pagando?</div>
            <erp-select
                placeholder="Selecione"
                :options="[{label: 'Próprio', value: 1},{label: 'Terceiros', value: 2}]"
                v-model="pagamento.tipoPagante"
                class="btn-pda btn-pda-rounded md"
            ></erp-select>
          </e-col>
          <e-col class="lrp-col">&nbsp;</e-col>
        </e-row>
        <e-row>
          <e-col class="lrp-col">
            <div class="text-center font-bold m-b-xs text-grey-7">CPF/CNPJ do Pagante</div>
            <erp-input v-model="pagamento.documentoPagante" class="btn-pda btn-pda-rounded md"/>
          </e-col>
          <e-col class="lrp-col">
            <div class="text-center font-bold m-b-xs text-grey-7">Nome do Pagante</div>
            <erp-input v-model="pagamento.nomePagamento" class="btn-pda btn-pda-rounded md"/>
          </e-col>
        </e-row>

        <e-row>
          <e-col class="lrp-col">
            <div class="text-center font-bold m-b-xs text-grey-7">Forma de Pagamento</div>
            <!--<forma-pagamento-select v-model="pagamento.forma" class="btn-pda btn-pda-rounded md" /> TODO: DELETAR -->
            <metodos-pagamento-lote-select v-model="pagamento.metodo" class="btn-pda btn-pda-rounded md"/>
          </e-col>
          <e-col class="lrp-col">
            <div class="text-center font-bold m-b-xs text-grey-7">Banco</div>
            <!--<banco-select v-model="pagamento.banco" class="btn-pda btn-pda-rounded md" />-->
            <conta-bancaria-select v-model="pagamento.banco" class="btn-pda btn-pda-rounded md"/>
          </e-col>
        </e-row>

        <e-row>
          <e-col class="lrp-col">
            <div class="text-center font-bold m-b-xs text-grey-7">Valor Pago</div>
            <erp-input v-model.lazy="pagamento.valor" v-money="money" class="btn-pda btn-pda-rounded md"/>
            <div v-if="null !== saldo" class="text-center font-12">
              <div v-if="lotes && lotes.length">
                Total em aberto <strong><u>para o(s) lote(s) {{ lotes.map(l => l.numero).join(', ') }}</u></strong>:<br> <strong
                  class="m-t-xs block">R$ {{ saldo|moeda }}</strong></div>
              <div v-else>Total em aberto: <strong>R$ {{ saldo|moeda }}</strong></div>
            </div>
          </e-col>
          <e-col class="lrp-col">
            <div class="text-center font-bold m-b-xs text-grey-7">Data do Pagamento</div>
            <erp-input v-model="pagamento.data" v-mask="'##/##/####'" class="btn-pda btn-pda-rounded md"/>
          </e-col>
        </e-row>

        <e-row v-show="false"> <!-- @TODO -->
          <e-col>
            <upload-comprovante/>
          </e-col>
        </e-row>

        <e-row class="m-t">
          <e-col class="lrp-col">
            <u-checkbox color="white" v-model="pagamento.enviarRecibo" label="Enviar Recibo para o Arrematante por E-mail" />
          </e-col>
        </e-row>

        <e-row class="m-t-lg m-l-n-lg m-r-n-lg items-center justify-center">
          <div>
            <u-btn @click="save" v-shortkey="['f10']" @shortkey.native="save" color="positive" size="lg" no-caps>
              <span class="m-r-lg">Confirmar e registrar pagamento</span>
              <span class="absolute-right font-11 m-r-sm flex items-center">F10</span>
            </u-btn>
            <u-btn @click="$uloc.window.close($root.wid)" color="grey" size="lg" label="Cancelar" no-caps class="m-l"/>
          </div>
        </e-row>
      </e-col>
    </e-row>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140"/>
    </div>
    <h5 class="m-t">Pagamento registrado com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)"/>
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else/>
</template>

<script>
import {
  WindowContent,
  ErpSelect,
  ErpInput
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../../layout/window/Loading'
import TimerCloseWindow from '../../../../layout/window/TimerClose'
import WindowSuccess from '../../../../layout/window/Success'
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
// import FormaPagamentoSelect from '../../include/FormaPagamentoSelect'
// import BancoSelect from '../../include/BancoSelect'
import UploadComprovante from './UploadComprovante'
import {convertRealToMoney, REAL_BRL} from '@/utils/money'
import {VMoney} from 'v-money'
import MetodosPagamentoLoteSelect from '../../../../cadastro/components/metodospagamentolote/include/MetodoSelect'
import ContaBancariaSelect from '../../../../cadastro/components/bancos/include/BancoSelect'
import {registrarPagamentoLote} from '@/domain/leiloes/services'
import {datePtToEn} from '@/utils/date'
import {UCheckbox} from 'uloc-vue'

export default {
  name: 'RegistrarPagamento',
  props: ['lotes', 'arrematante', 'comprador', 'saldoDevedor'/*, 'saldo'*/],
  directives: {money: VMoney},
  data() {
    const hoje = this.$options.filters.formatDateNoIso(new Date(), 'dd/MM/yyyy')
    return {
      money: REAL_BRL,
      status: 'Registrando pagamento',
      loading: false,
      errors: null,
      success: false,
      pagamento: {
        tipoPagante: null,
        data: hoje,
        nomePagamento: null,
        documentoPagante: null,
        observacoes: null,
        metodo: null,
        banco: null,
        valor: null,
        arremate: null,
        lotes: null,
        enviarRecibo: true,
        arrematante: this.comprador ? this.comprador.id : (this.arrematante ? this.arrematante.id : null)
      },
      saldo: null
    }
  },
  mounted() {
    if (this.lotes && this.lotes.length === 1 && this.lotes[0].arremate) {
      this.pagamento.arremate = this.lotes[0].arremate.id
      this.saldo = this.lotes[0].saldoDevedor
    } else if (this.lotes.length > 1) {
      let saldo = 0
      this.lotes.map(lote => {
        saldo = saldo + lote.saldoDevedor
      })
      this.pagamento.lotes = this.lotes.map(l => l.id)
      this.saldo = saldo
    } else if (this.saldoDevedor) {
      this.saldo = convertRealToMoney(this.saldoDevedor)
    }
    if (this.getComprador) {
      this.pagamento.tipoPagante = 1
      this.pagamento.documentoPagante = String(this.getComprador.pessoa.document).length < 12 ? this.$options.filters.formataCpf(this.getComprador.pessoa.document) : this.$options.filters.formataCnpj(this.getComprador.pessoa.document)
      this.pagamento.nomePagamento = this.getComprador.pessoa.name
    }
  },
  computed: {
    getComprador() {
      if (this.comprador) {
        return this.comprador
      }
      return this.arrematante
    }
  },
  methods: {
    save() {
      console.log('Save')
      let data = JSON.parse(JSON.stringify(this.pagamento))
      data.valor = convertRealToMoney(data.valor)
      data.data = datePtToEn(data.data)
      let errors = []
      if (!data.metodo) {
        errors.push('Informe um método de pagamento.')
      }
      if(!data.banco){
        errors.push('Informe um banco')
      }
      if(errors.length){
        this.$uloc.dialog({
          title: 'Verifique seu preenchimento',
          message: errors.join(', '),
          noCaps: true,
          ok: `Ok`
        })
        return
      }
      this.loading = true
      registrarPagamentoLote(data)
          .then(response => {
            const cbSuccess = () => {
              this.success = true
              this.$nextTick(() => {
                this.$refs.timer.start()
                this.$uloc.window.emit(this.$root.wid, 'update', this.props)
                // this.load()
                window.setTimeout(() => {
                  this.loading = false
                  this.success = false
                  this.$uloc.window.close(this.$root.wid, 'update', this.props)
                }, 3000)
              })
            }
            cbSuccess()
          })
          .catch(({response}) => {
            console.log(response)
            this.loading = false
            this.alertApiError(response)
          })
    }
  },
  components: {
    ContaBancariaSelect,
    MetodosPagamentoLoteSelect,
    UploadComprovante,
    // BancoSelect,
    // FormaPagamentoSelect,
    ECol,
    ERow,
    WindowSuccess,
    TimerCloseWindow,
    WindowLoading,
    WindowContent,
    ErpSelect,
    ErpInput,
    UCheckbox
  }
}
</script>

<style lang="stylus">
.lote-registro-pagamento {
  .lrp-col {
    padding 0 10px
  }

  input {
    font-weight bold
  }
}
</style>
