<template>
  <erp-select
      @input="__set"
      :loading="loading"
      :placeholder="placeholder || 'Nenhum'"
      :options="asyncdataFiltered"
      v-model="model"
      :multiple="multiple"
      :simpleBorder="simpleBorder"
  ></erp-select>
</template>

<script>
import SelectMixin from '../../../../../mixins/SelectMixin'
import {listBancos} from '../../../../../domain/cadastro/services/index'

export default {
  name: 'ContaBancariaSelect',
  mixins: [SelectMixin],
  props: {
    listAll: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      asyncdata: []
    }
  },
  computed: {
    asyncdataFiltered () {
      return this.listAll ? this.asyncdata : this.asyncdata.filter(item => !item.parent)
    }
  },
  watch: {
    model (v) {
      this.$nextTick(() => {
        if (!this.model) {
          return
        }
        let selected = this.asyncdata.filter(item => item.id === this.model)
        if (!selected) {
          return
        }
        this.$emit('selected', selected[0])
      })
    }
  },
  mounted () {
    this.load()
  },
  components: {},
  methods: {
    load () {
      this.loading = true
      listBancos(1000, 1, '') // TODO: LIMIT
        .then((response) => {
          this.loading = false
          if (!Array.isArray(response.data.result)) {
            return
          }
          this.$emit('load', response.data.result)
          let list = response.data.result.map((obj) => {
            return {
              ...obj,
              label: obj.nome,
              value: obj.id
            }
          })

          !this.multiple && !this.required && list.unshift({label: this.placeholder || 'Nenhum', value: null})
          this.asyncdata = list
        })
        .catch((error) => {
          this.loading = false

        })
    }
  }
}
</script>
